import React from 'react'
import Header from '../Header/Header'
import Banner from '../Home/Banner'
import Explore from '../Home/Explore'
import Cards from '../Home/Cards'
import Footer from '../Footer/Footer'
import Section3 from '../Features/Section3'
import About from '../About/About'
import { Helmet } from 'react-helmet'

const Home= () => {
  return (
    <>
    <Header/>
    <Banner/>
    <Explore/>
    <Cards/>
    <Footer/>
    </>
  )
}

export default Home
