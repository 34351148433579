import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import "../VendorForm.css";
import axios from 'axios';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Bars, LineWave } from 'react-loader-spinner';


const EventForm = () => {

  //UseStates
  const [event,setEvent]=useState({name:"",phoneNumber:"",email:"",eventName:"",eventDate:"",city:"",country:"",pinCode:"",eventDescription:""})
  const [show, setShow] = useState(false);
  const [isSubmit,setIsSubmit]=useState(false)

  const handleClose = () => setShow(false);
  
  //Submit handleEventform function
  const handleEventform=async(e:any)=>{
    setIsSubmit(true)
    e.preventDefault()
    await axios.post("https://wishiton-registration-api.onrender.com/EventRequest",{name:event.name,phoneNumber:event.phoneNumber,email:event.email,eventName:event.eventName,eventDate:event.eventDate,city:event.city,country:event.country,pinCode:event.pinCode,Description:event.eventDescription})
    .then((success)=>{
      setShow(true);
      setIsSubmit(false)
      setEvent({name:"",phoneNumber:"",email:"",eventName:"",eventDate:"",city:"",country:"",pinCode:"",eventDescription:""})
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  return (
    <>
      <section style={{backgroundColor:"#B94F98",paddingTop:"6rem"}}>
        <Container>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pt-5 pb-5'>
                    <div className='formVendor  d-flex justify-content-center align-items-center'>
                        <div className='pt-5 pb-5 px-2 py-1'>
                            <form onSubmit={(e)=>handleEventform(e)}>
                                <h2 className='vendor-formH'>Event Registration Application Form</h2>
                                <label className='d-block vendor-label pb-1'>Name<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your name' type='text' className='vendor-input p-2 px-3' required value={event.name} onChange={(e)=>setEvent({...event,name:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Phone Number<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your phone number' type='text' className='vendor-input p-2 px-3' required value={event.phoneNumber} onChange={(e)=>setEvent({...event,phoneNumber:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Email<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your email' type='email' className='vendor-input p-2 px-3' required value={event.email} onChange={(e)=>setEvent({...event,email:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Event name</label>
                                <input placeholder='Enter your event name' type='text' className='vendor-input p-2 px-3' value={event.eventName} onChange={(e)=>setEvent({...event,eventName:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Event date<span style={{color:"red"}}>*</span></label>
                                <input placeholder="Select a event date" type='date' className='vendor-input p-2 px-3'  required value={event.eventDate}  onChange={(e)=>setEvent({...event,eventDate:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>City<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your city' type='text' className='vendor-input p-2 px-3'  required value={event.city} onChange={(e)=>setEvent({...event,city:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Country<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your country' type='text' className='vendor-input p-2 px-3'  required value={event.country} onChange={(e)=>setEvent({...event,country:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Pin code<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your pin code' type='text' className='vendor-input p-2 px-3'  required  value={event.pinCode} onChange={(e)=>setEvent({...event,pinCode:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Description about event</label>
                                <textarea className='vendor-input p-2 px-3' rows={5} required value={event.eventDescription} onChange={(e)=>setEvent({...event,eventDescription:e.target.value})}/>
                                <div className='text-center pt-5'>
                                  {
                                    isSubmit?<button className='vendor-btn'><Bars
                                    height="35"
                                    width="80"
                                    color="#FFFFFF"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                /></button>
                                :<button className='vendor-btn' type='submit'>Submit</button>
                                  }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Event Registration Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>Your data recieved Successfully</Modal.Body>
            </Modal>



            
        </Container>
      </section>
    </>
  )
}

export default EventForm
