import React from 'react'
import Section3 from '../Features/Section3'
import Footer from '../Footer/Footer'
const Features = () => {
  return (
    <>
      <Section3/>
      <Footer/>
    </>
  )
}

export default Features
