import React, { useRef, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import "./Header.css"
import { GoHome } from "react-icons/go";
import { IoIosPhonePortrait } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { MdOutlineHandshake } from "react-icons/md";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { RiCalendarEventLine } from "react-icons/ri";
import { LuGift } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { IoMdInformationCircleOutline } from "react-icons/io";




const Header = () => {
  const [isBusiness,setIsBusiness]=useState(false)
  const [isFeature,setIsFeature]=useState(false)
    const iconStyle = {
        fontSize: '1.5rem', 
        marginBottom: '3px',
        marginRight:"10px"
    }

    //Navigation
    const navigate=useNavigate()

    const navPrivacy=()=>{
      navigate("/privacy-policy")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const navHome=()=>{
      navigate("/")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    const navVendorForm=()=>{
      navigate("/become-a-vendor")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const navFranchiseForm=()=>{
      navigate("/franchise")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const navEvent=()=>{
      navigate("/event-plan")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const navFeature=()=>{
      navigate("/features")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const navAbout=()=>{
      navigate("/about-us")
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    const handleBusinessEnter = () => {
      setIsBusiness(true);
    };
  
    const handleBusinessLeave = () => {
      setIsBusiness(false);
    };
    const handleFeatureEnter=()=>{
      setIsFeature(true)
    }
    const handleFeatureLeave=()=>{
      setIsFeature(false)
    }
  
    
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary navbar" fixed='top'>
      <Container className='contain'>
          <Navbar.Brand><img src="assert/Headerimg/WishitOn Logo-01.png" className='img-fluid' style={{width:"auto",height:"80px",cursor:"pointer"}} onClick={navHome}/></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='flex-grow-0'>
                  <Nav className="me-auto">
                  <Nav.Link  className='fs-6 text-white fw-400' id='home' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}}  onClick={navHome}><GoHome style={iconStyle}/>Home</Nav.Link>
                      <NavDropdown  show={isFeature} 
                          onMouseEnter={handleFeatureEnter}
                          onMouseLeave={handleFeatureLeave}  title={<span style={{ color: '#FFFFFF',fontWeight:"400"}} 
                          ><IoIosPhonePortrait style={iconStyle}/>Features</span>} id="basic-nav-dropdown features ser"  className='fs-6 what'>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#remainder' onClick={navFeature}><LuGift style={iconStyle}/>Birthday Reminder</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#notification' onClick={navFeature}><LuGift style={iconStyle}/>Birthday notification</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#voice-assistant' onClick={navFeature}><LuGift style={iconStyle}/>Meet ”wish” Voice assistant </NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#birthday-wishes' onClick={navFeature}><LuGift style={iconStyle}/>Send Birthday Wishes</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#bulk-wishes' onClick={navFeature}><LuGift style={iconStyle}/>Send Bulk Wishes</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#gift-shop' onClick={navFeature}><LuGift style={iconStyle}/>Gift Shop</NavDropdown.Item>
                            {/* <NavDropdown.Item className='pt-3 pb-3 drop'><LuGift style={iconStyle}/>Send & Buy Gifts</NavDropdown.Item> */}
                      </NavDropdown>
                      <NavDropdown
                          title={
                            <span
                              style={{ color: '#FFFFFF', fontWeight: '400' }}
                              className='business'
                            >
                              <HiUserGroup style={iconStyle} />
                              Business with us
                            </span>
                          }
                          id="basic-nav-dropdown"
                          className='fs-6 what'
                          show={isBusiness} 
                          onMouseEnter={handleBusinessEnter}
                          onMouseLeave={handleBusinessLeave}
                        >
                          <div>
                            <NavDropdown.Item className='pt-2 pb-2 drop' onClick={navVendorForm}>
                              <MdOutlineHandshake style={iconStyle} />
                              Become a Vendor
                            </NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' onClick={navFranchiseForm}>
                              <LiaUserFriendsSolid style={iconStyle} />
                              Become a Franchise
                            </NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' onClick={navEvent}>
                              <RiCalendarEventLine style={iconStyle} />
                              Plan an event
                            </NavDropdown.Item>
                          </div>
                        </NavDropdown>
                      <Nav.Link  className='fs-6  text-white fw-400' id='privacy' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}} onClick={navAbout}><IoMdInformationCircleOutline style={iconStyle}/>About</Nav.Link>
                      <Nav.Link className='fs-6  text-white fw-400' id='privacy' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}} onClick={navPrivacy}><MdOutlinePrivacyTip style={iconStyle}/>Privacy policy</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

export default Header
