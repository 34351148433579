import "./Features.css"
import { Container } from 'react-bootstrap';
import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
const iconStyle = {
    fontSize: '1.5rem', 
    marginBottom: '3px',
    marginRight:"10px"
}

const Section3 = () => {
    const navigate=useNavigate()

    useEffect(() => {
        AOS.init();
    
        const handleScroll = () => {
          AOS.refresh();
          const elements = document.querySelectorAll('[data-aos]');
          
          elements.forEach(element => {
            const top = element.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (top < windowHeight - 100) {
              element.classList.add('aos-animate'); 
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
      <Header/>
    {/* <Navbar expand="lg" className="bg-body-tertiary navbar" fixed='top'>
      <Container className='contain'>
          <Navbar.Brand><img src='/assert/Headerimg/LOGO.png' className='img-fluid' style={{width:"auto",height:"70px",cursor:"pointer"}} onClick={navHome}/></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='flex-grow-0'>
                  <Nav className="me-auto">
                  <Nav.Link  className='fs-6 text-white fw-400' id='home' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}} onClick={navHome}><GoHome style={iconStyle}/>Home</Nav.Link>
                      <NavDropdown title={<span style={{ color: '#FFFFFF',fontWeight:"400"}}
                          ><IoIosPhonePortrait style={iconStyle}/>Our Features</span>} id="basic-nav-dropdown features ser"  className='fs-6 what'>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#remainder'><LuGift style={iconStyle}/>Birthday Remainder</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#notification'><LuGift style={iconStyle}/>Birthday notification</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#voiceassistant'><LuGift style={iconStyle}/>Meet ”wish” Voice assistant </NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#bWishes'><LuGift style={iconStyle}/>Send Birthday Wishes</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#bulkWishes'><LuGift style={iconStyle}/>Send Bulk Wishes</NavDropdown.Item>
                            <NavDropdown.Item className='pt-2 pb-2 drop' href='#Giftshop'><LuGift style={iconStyle}/>Gift Shop</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title={<span style={{ color: '#FFFFFF',fontWeight:"400" }} ><HiUserGroup style={iconStyle}/>Business with us</span>} id="basic-nav-dropdown"  className='fs-6 what' >
                            <NavDropdown.Item  className='pt-2 pb-2 drop' onClick={navVendorForm}><MdOutlineHandshake style={iconStyle}/>Become a Vendor</NavDropdown.Item>
                            <NavDropdown.Item  className='pt-2 pb-2 drop' onClick={navFranchiseForm}><LiaUserFriendsSolid style={iconStyle}/>Become a Franchise</NavDropdown.Item>
                            <NavDropdown.Item  className='pt-2 pb-2 drop' onClick={navEvent}><RiCalendarEventLine style={iconStyle}/>Plan a event</NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link  className='fs-6  text-white fw-400' id='privacy' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}} onClick={navAbout}><IoMdInformationCircleOutline style={iconStyle}/>About</Nav.Link>
                      <Nav.Link  className='fs-6  text-white fw-400' id='privacy' style={{paddingRight:"1rem",display:"flex",alignItems:"center"}} onClick={navPrivacy}><MdOutlinePrivacyTip style={iconStyle}/>Privacy policy</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
      </Container>
    </Navbar> */}
    <section className='pb-5' style={{backgroundColor:"#BA4F98",paddingTop:"6rem"}} id='remainder'>
        <Container>
            <div className='row pt-5 pb-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <div>
                        <img src='/assert/Featuresimg/Birthday Reminder.png' className='img-fluid pb-3' data-aos="zoom-in-down" data-aos-duration="1000"/>
                        <p  data-aos="zoom-in-down" data-aos-duration="1000" className='pb-4' style={{color:"white"}}>Are you tired of forgetting birthdays and special events? Fret not! Introducing Wish-It-On the ultimate solution to keep track of all your important dates and receive timely reminders.</p>
                        <div className='row' style={{paddingLeft:"0.5rem"}}>
                            <div className='col-lg-3 col-md-3 p-1 d-flex justify-content-center'>
                                <a href='https://play.google.com/store/apps/details?id=com.wish_it_on.yts' target='_blank' style={{display:"contents"}}><img src='/assert/Footerimg/playstore.png' className='img-fluid appimg' data-aos="zoom-in-up" data-aos-duration="1000"/></a>
                            </div>
                            <div className='col-lg-3 col-md-3 p-1 d-flex justify-content-center'>
                                <a href='https://apps.apple.com/us/app/wish-it-on/id6473829634' target='_blank'><img src='/assert/Footerimg/appstore.png' className='img-fluid appimg ' data-aos="zoom-in-down" data-aos-duration="1000"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <img src='assert/Featuresimg/Group 135.png' className='img-fluid animated'  data-aos="zoom-in" data-aos-duration="1000" />
                </div>
            </div>
        </Container>
      </section>
    <section className='pt-5 pb-5' style={{backgroundColor:"#78c7ce"}} id='notification'>
        <Container>
            <div className='row pt-5 pb-3' >
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <div>
                        <h2 className='send-B pb-4' data-aos="flip-right" data-aos-duration="1000">Birthday Notification</h2>
                        <p  data-aos="flip-right" data-aos-duration="1000" className='pb-4'>🔔 Stay Ahead with Wish-It-On Notifications! Set It and Forget It! 📅</p>
                        <p data-aos="flip-right" data-aos-duration="1000">Tired of relying on memory to remember birthdays and events? We've got your back! Not only helps you keep track but also ensures you never miss a celebration with our friendly notification features.</p>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <img src='assert/Featuresimg/Group 136.png' className='img-fluid' data-aos="zoom-in" data-aos-duration="1000"/>
                </div>
            </div>
        </Container>
      </section>
    <section className='pt-5 pb-5' id='voice-assistant'>
        <Container>
            <div className='row pt-5 pb-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center order-md-2 order-lg-1'>
                    <img src='assert/Featuresimg/Group 139.png' className='img-fluid'  data-aos="zoom-in" data-aos-duration="1000" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center order-md-1 order-lg-2'>
                    <div>
                        <h2 className='send-B pb-4' data-aos="flip-right" data-aos-duration="1000">Meet “Wish” Voice assistant</h2>
                        <p data-aos="flip-right" data-aos-duration="1000">Meet "Wish," the voice assistant that makes event creation a breeze in Wish-It-On, No more typing or navigating through menus – just say it, and "Wish" will take care of the rest.</p>
                    </div>
                </div>
            </div>
        </Container>
      </section>
      <section className='pt-5 pb-5' id='birthday-wishes'>
        <Container>
            <div className='row pt-5 pb-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <div>
                        <h2 className='send-B pb-4' data-aos="flip-right" data-aos-duration="1000">Send Birthday Wishes</h2>
                        <p data-aos="flip-right" data-aos-duration="1000">🎈 Celebrate your friends’ birthday by sending them the most suitable birthday card, you can choose from dozens of greeting cards included in your birthday reminder app! Choose the one you like best and match it with a birthday greeting phrase or write your own! 🎉</p>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <img src='/assert/Featuresimg/sendwish.png' className='img-fluid' style={{width:"70%"}} data-aos="zoom-in" data-aos-duration="1000"/>
                </div>
            </div>
        </Container>
      </section>
      <section className='pt-5 pb-5' id='bulk-wishes'>
        <Container>
            <div className='row pt-5 pb-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center order-md-2 order-lg-1'>
                    <img src='assert/Featuresimg/Group 141.png' className='img-fluid'  data-aos="zoom-in" data-aos-duration="1000" />
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center order-md-1 order-lg-2'>
                    <div>
                        <h2 className='send-B pb-4' data-aos="flip-right" data-aos-duration="1000">Send Bulk Wishes</h2>
                        <p data-aos="flip-right" data-aos-duration="1000">Have a long list of celebrations to acknowledge? With Wish-It-On, you can now send heartfelt wishes in bulk, making your well-wishing journey even more efficient and delightful.</p>
                    </div>
                </div>
            </div>
        </Container>
      </section>
      <section className='pt-5 pb-5' style={{backgroundColor:"#78c7ce"}} id='gift-shop'>
        <Container>
            <div className='row pt-5 pb-3'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <div>
                        <h2 className='send-B pb-4' data-aos="flip-right" data-aos-duration="1000">Gift Shop</h2>
                        <p data-aos="flip-right" data-aos-duration="1000" className='pb-4'>🎁 Gifts Galore at Wish-It-On - Explore Our Gift Shop and Send Love in Every Box! 🎀</p>
                        <p data-aos="flip-right" data-aos-duration="1000">Not only can you send heartfelt wishes with ease, but now you can also add a touch of delight to your celebrations with the Wish-It-On Gift Shop! Explore a curated selection of gifts, and pre-book the perfect surprises for your friends and relatives, making every occasion extra special</p>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <img src='assert/Featuresimg/32768014_responsive_device_64.png' className='img-fluid'  data-aos="zoom-in" data-aos-duration="1000" />
                </div>
            </div>
        </Container>
      </section>
    </>
  )
}

export default Section3
