import React from 'react'
import Container from 'react-bootstrap/Container';
import "./Privacy.css"

const Privacy = () => {
  return (
    <>
      <section style={{paddingTop:"6rem"}}>
        <Container>        
            <div className='row pt-5 pb-5' >     

                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>

                    <h2 className='privacy-H'>Wishiton Privacy Policy</h2>
                    <p className='privacy-choose'>Thank you for choosing Wishiton . Your privacy is important to us, and we are committed to protecting the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our Wishiton application .By using the App, you agree to the terms of this Privacy Policy. If you do not agree with our practices, please do not use the App.</p>
                    <h2 className='info'>Information We Collect:</h2>
                    <h3 className='info-Per'>1. Personal Information:</h3>
                        <li className='li-Priv'>When you create an account, we may collect your name, email address, and profile picture.</li>
                        <li className='li-Priv'>If you choose to log in via Gmail or Facebook, we may collect information from those accounts.</li>
                        <li className='li-Priv'>We collect information you provide when adding birthdays, events, and purchasing gifts, including names, dates, and addresses.</li>
                    <h3 className='info-Per'>2. Payment Information:</h3>    
                        <li className='li-Priv'>If you make purchases on the App, we collect payment information, such as credit card details. However, we do not store this information; it is securely processed by our payment service provider.</li>
                    <h3 className='info-Per'>3. Uploaded Content:</h3>
                        <li className='li-Priv'>You may choose to upload images or videos for sending wishes. These will be stored securely and shared only as directed by you.</li>
                    <h3 className='info-Per'>4. Location Information:</h3>
                        <li className='li-Priv'>We collect the delivery address for gifts and events based on the location you provide.</li>
                    <h3 className='info-Per'>5. Voice Assistant Data:</h3>
                        <li className='li-Priv'>Voice commands used for adding events through the voice assistant are processed and stored temporarily to improve the functionality of the voice feature.</li>
                    <h2 className='info'>How We Use Your Information:</h2>
                    <h3 className='info-Per'>1. To Provide Services:</h3>
                        <li className='li-Priv'>We use your information to provide the services offered by the App, including sending reminders, delivering gifts, and facilitating communication.</li>
                    <h3 className='info-Per'>2. Personalization:</h3>
                        <li className='li-Priv'>We may use your data to personalize your experience within the App, including recommendations for gifts and event suggestions.</li>
                    <h3 className='info-Per'>3. Communication:</h3>
                        <li className='li-Priv'>We may use your contact information to send you notifications, updates, and marketing materials related to the App. You can opt out of marketing communications at any time.</li>
                    <h2 className='info'>Information Sharing:</h2>
                    <h3 className='info-Per'>1. Third-Party Service Providers:</h3>
                        <li className='li-Priv'>We may share your information with third-party service providers who assist us in delivering our services, processing payments, and improving the App.</li>
                    <h3 className='info-Per'>2. Legal Compliance:</h3>
                        <li className='li-Priv'>We may disclose your information to comply with legal obligations or respond to requests from law enforcement agencies.
                            Security: We implement industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</li>
                    <h2 className='info'>Handling Google User Data:</h2>
                    <h3 className='info-Per'> 1. Sensitive Scopes Information:</h3>
                        <li className='li-Priv'>Wishiton access your google calendar events provided by google OAuth Api's which will help wishiton to save your birthday events and you get reminder.</li>
                        <li className='li-Priv'>We assure that the retrived google user data are secured as per the terms and privacy policy condition of <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' style={{display:"contents"}} target='_blank'>Limited Use Requirements</a> and it's must comply with the <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank' style={{display:"contents"}}> Google API Services User Data Policy.</a></li>
                        <li className='li-Priv'>Wishiton assures that the share google user data is not shared anywhere and we didn't store that data also in our DB.</li>
                        <li className='li-Priv'>Wishiton doesn't do any perform like edit or delete the data information of the sensitive scopes.</li>
                    <h2 className='info'>Refund Policy:</h2>
                        <li className='li-Priv'>Refund in cases of Cancellations are mentioned in Cancellation policy, kindly refer the same.</li>
                        <li className='li-Priv'>The acceptance of order is subject to availability of products, delivery areas and time. The order may or may not be accepted. The order might be cancelled after assessing the circumstances and communicated to customer. In such case amount paid by customer is fully refunded to bank account.</li>
                        <li className='li-Priv'>Only one attempt for delivery will be made. Please confirm availability of recipient before choosing delivery time. If recipient is not available at the time of delivery, second attempt will not be made and order will be cancelled. No refund will be made in such case.</li>
                        <li className='li-Priv'>The refunds are only initiated once the customer confirms over mail that where the refund has to be done -  bank account.</li>
                        <li className='li-Priv'>Refunds are processed to your payment method within 15 working days.</li>
                    <h2 className='info'>Address Change requests:</h2>
                        <li className='li-Priv'>Change in the address is permitted free of cost only before 24 hours of delivery.</li>
                        <li className='li-Priv'>Within 24 hours period before delivery , if address is changed we charge Rs 200/-.</li>
                        <li className='li-Priv'>Sometimes the address change request can not be accepted, in that case order will not be refunded or cancelled.</li>
                        <li className='li-Priv'>We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.</li>
                        <li className='li-Priv'>To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.</li>
                        <li className='li-Priv'>If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.</li>
                        <li className='li-Priv'>You can always contact us for any return question at <a href='mailto:noreply@wishiton.com' style={{marginLeft:"5px"}}>noreply@wishiton.com</a> .</li>
                    <h2 className='info'>Damages and issues:</h2>
                        <li className='li-Priv'>Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.</li>
                    <h2 className='info'>Exceptions / Non-returnable items:</h2>
                        <li className='li-Priv'>Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item.</li>
                        <li className='li-Priv'>Unfortunately, we cannot accept returns on sale items or gift cards.</li>
                    <h2 className='info'>Exchanges:</h2>
                        <li className='li-Priv'>The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.</li>
                    <h2 className='info'>Refunds:</h2>
                        <li className='li-Priv'>We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too.</li>
                        <li className='li-Priv'>If more than 15 business days have passed since we approved your return, please contact us at <a href='mailto:noreply@wishiton.com' style={{marginLeft:"5px"}}>noreply@wishiton.com</a> .</li>
                    <div className='pt-3'><h3 className='info-Third'>Third-Party Integrations:</h3><li className='li-Third'> If you connect Alexa through Wi-Fi and Bluetooth, please review Alexa's privacy policy for information on how your data is handled by their services. </li></div><br/>
                    <div><h3 className='info-Third'>Changes to the Privacy Policy:</h3><li className='li-Third'>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</li></div><br/>
                    <div><h3 className='info-Third'>Contact Us:</h3><li className='li-Third'>If you have any questions or concerns about our Privacy Policy, please contact us at <a href='mailto:noreply@wishiton.com' target='_blank'>noreply@wishiton.com</a>.
                        By using the Wishiton application, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</li></div>
                </div>
            </div>
        </Container>
      </section>
    </>
  )
}

export default Privacy
