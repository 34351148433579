import React, { useState } from 'react'
import "../VendorForm.css";
import "../FranchiseForm/Franchiseform.css"
import makeAnimated from 'react-select/animated';
import { Button, Container, Modal } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';

const Franchiseform = () => {
    //useStates
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [franchise,setFranchise]=useState({contactPersonName:"",phoneNumber:"",email:"",city:"",country:"",pinCode:"",isGSTRegistered:""})
    const [show, setShow] = useState(false);
    const [fsubmit,setFsubmit]=useState(false)

  const handleClose = () => setShow(false);

    //Checkbox function
    const handleCheckboxChange = (checkboxNumber:any) => {
        if (checkboxNumber === 1) {
            setFranchise({...franchise,isGSTRegistered:"Yes"})
            setIsChecked1(true);
            setIsChecked2(false);
        } else if (checkboxNumber === 2) {
            setFranchise({...franchise,isGSTRegistered:"No"})
            setIsChecked1(false);
            setIsChecked2(true);
        }
    };

    //Submit handleFranchise function
    const handleFranchise=async(e:any)=>{
        e.preventDefault()
        setFsubmit(true)
        await axios.post("https://wishiton-registration-api.onrender.com/FranchiseRequest",{contactperson:franchise.contactPersonName,phoneNumber:franchise.phoneNumber,email:franchise.email,city:franchise.city,country:franchise.country,pincode:franchise.pinCode,Gst:franchise.isGSTRegistered})
        .then((success)=>{
            setShow(true);
            setFsubmit(false)
            setIsChecked1(false);
            setIsChecked2(false);
            setFranchise({contactPersonName:"",phoneNumber:"",email:"",city:"",country:"",pinCode:"",isGSTRegistered:""})
        })
        .catch((error)=>{
            console.log(error.message);
        })
        
    }
  return (
    <>
      <section style={{backgroundColor:"#B94F98",paddingTop:"6rem"}}>
        <Container>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pt-5 pb-5'>
                    <div className='formVendor  d-flex justify-content-center align-items-center'>
                        <div className='pt-5 pb-5 px-2 py-1'>
                            <form onSubmit={(e)=>handleFranchise(e)}>
                                <h2 className='vendor-formH'>Franchise Registration Application Form</h2>
                                <p className='vendor-formP pb-4'>Fill all the questions in the form to become a franchise on Wish-It-On</p>
                                <label className='d-block vendor-label pb-1 pt-3'>Contact person name<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your name' type='text' className='vendor-input p-2 px-3' value={franchise.contactPersonName} required onChange={(e)=>setFranchise({...franchise,contactPersonName:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Phone Number<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your phone number' type='text' className='vendor-input p-2 px-3'  required  value={franchise.phoneNumber} onChange={(e)=>setFranchise({...franchise,phoneNumber:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Email<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your email' type='email' className='vendor-input p-2 px-3' required  value={franchise.email}  onChange={(e)=>setFranchise({...franchise,email:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>City<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your city' type='text' className='vendor-input p-2 px-3'  required  value={franchise.city} onChange={(e)=>setFranchise({...franchise,city:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Country<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your country' type='text' className='vendor-input p-2 px-3'  value={franchise.country} required onChange={(e)=>setFranchise({...franchise,country:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Pin code<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your pin code' type='text' className='vendor-input p-2 px-3'  value={franchise.pinCode} required onChange={(e)=>setFranchise({...franchise,pinCode:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3 '>Have you GST registered<span style={{color:"red"}}>*</span></label>
                                <div className='d-flex'>
                                    <div className='d-flex'><Checkbox  onChange={() => handleCheckboxChange(1)} className='p-2' checked={isChecked1}/><span className='checkbox-Vendor d-flex justify-content-center align-items-center'>Yes</span></div>
                                    <div className='d-flex px-5'><Checkbox  onChange={() => handleCheckboxChange(2)} className='p-2' checked={isChecked2}/><span className='checkbox-Vendor d-flex justify-content-center align-items-center'>No</span></div>
                                </div>
                                <div className='text-center pt-5'>
                                  {fsubmit?<button className='vendor-btn'><Bars
                                    height="35"
                                    width="80"
                                    color="#FFFFFF"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                /></button>: <button className='vendor-btn' type='submit'>Submit</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

          <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>Thanks for showing interest !</Modal.Title>
            </Modal.Header>
            <Modal.Body>We will respond you soon...</Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  )
}

export default Franchiseform
