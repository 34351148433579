import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import "./Home.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const Cards = () => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.refresh();
      const elements = document.querySelectorAll('[data-aos]');
      
      elements.forEach(element => {
        const top = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight - 100) {
          element.classList.add('aos-animate'); 
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <section style={{backgroundColor:"#B94F98"}}>
        <Container>
            <div className='row pt-5 pb-5'>
                <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center'>
                    <img src='/assert/Homeimg/Group 91.png' className='img-fluid' style={{width:"70%"}} data-aos="fade-right" data-aos-offset="300" data-aos-duration="1000" data-aos-easing="ease-in-sine"/>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12  d-flex justify-content-center align-items-center'>
                    <div>
                    <h2 className='b-Head pb-3' data-aos="fade-up" data-aos-duration="1000">Benefits of Using WishIton</h2>
                    <li className='b-LI ' data-aos="fade-up" data-aos-duration="1000">Never Forget a Birthday:</li>
                    <p className='b-Para' data-aos="fade-up" style={{paddingLeft:"1rem"}} data-aos-duration="1000">With WishIton, you'll always remember important birthdays, ensuring your loved ones feel valued and remembered.</p>
                    <li className='b-LI' data-aos="fade-up" data-aos-duration="1000">Personalized Celebrations:</li>
                    <p className='b-Para' data-aos="fade-up" style={{paddingLeft:"1rem"}} data-aos-duration="1000">Customize birthday messages and reminders to add a personal touch to each celebration.</p>
                    <li className='b-LI' data-aos="fade-up" data-aos-duration="1000">Time-Saving Convenience:</li>
                    <p className='b-Para' data-aos="fade-up" style={{paddingLeft:"1rem"}} data-aos-duration="1000">Automatically sync birthdays from your contacts and calendars, saving you time and effort.</p>
                    <li className='b-LI' data-aos="fade-up" data-aos-duration="1000">Stress-Free Planning:</li>
                    <p className='b-Para' data-aos="fade-up" style={{paddingLeft:"1rem"}} data-aos-duration="1000">Receive timely reminders so you can prepare gifts, cards, or special plans without last-minute rushes.</p>
                    <li className='b-LI' data-aos="fade-up" data-aos-duration="1000">Strengthen Relationships:</li>
                    <p className='b-Para' data-aos="fade-up" style={{paddingLeft:"1rem"}} data-aos-duration="1000">Show your appreciation and care by consistently acknowledging and celebrating the birthdays of friends and family.</p>

                    </div>
                </div>
            </div>
        </Container>
      </section>
      <section className='testimonial'>
        <Container>
            <div className='py-5'>
              <div className='text-center pb-3'>
                <h2 className='explore-H' style={{color:"#000"}} data-aos="fade-right">Testimonials</h2>
              </div>

            <div className='carousel-wrapper'>
            <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      partialVisible={false}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div className='px-3'>
        <div className='p-2 card-slide'>
          <div className='d-flex justify-content-center p-2'>
            <img src='/assert/Homeimg/boy.png' style={{width: "80px"}} className='img-fluid' alt="Person" />
          </div>
          <div className='text-center'>
            <h3 style={{fontSize: "1.2rem", fontWeight: "600"}}>John D</h3>
            <p className='pt-2'>"WishIton has been a game-changer for me. I no longer miss any birthdays, and my friends are impressed by the personalized messages I send. Highly recommend it!"</p>
          </div>
        </div>
      </div>
      <div className='px-3'>
        <div className='p-2 card-slide'>
          <div className='d-flex justify-content-center p-2'>
            <img src='/assert/Homeimg/girl.png' style={{width: "80px"}} className='img-fluid' alt="Person" />
          </div>
          <div className='text-center'>
            <h3 style={{fontSize: "1.2rem", fontWeight: "600"}}>Sarah L</h3>
            <p className='pt-2'>"I love how easy it is to sync WishIton with my contacts. The reminders are timely, and the app's interface is very user-friendly."</p>
          </div>
        </div>
      </div>
      <div className='px-3'>
        <div className='p-2 card-slide'>
          <div className='d-flex justify-content-center p-2'>
            <img src='/assert/Homeimg/boy.png' style={{width: "80px"}} className='img-fluid' alt="Person" />
          </div>
          <div className='text-center'>
            <h3 style={{fontSize: "1.2rem", fontWeight: "600"}}>Michael T</h3>
            <p className='pt-2'>"WishIton has helped me stay organized and thoughtful. My family appreciates the effort I put into remembering their special days, all thanks to this app."</p>
          </div>
        </div>
      </div>
      <div className='px-3'>
        <div className='p-2 card-slide'>
          <div className='d-flex justify-content-center p-2'>
            <img src='/assert/Homeimg/girl.png' style={{width: "80px"}} className='img-fluid' alt="Person" />
          </div>
          <div className='text-center'>
            <h3 style={{fontSize: "1.2rem", fontWeight: "600"}}>Emma W</h3>
            <p className='pt-2'>"The customized messages feature is fantastic! It makes every birthday greeting feel special and unique."</p>
          </div>
        </div>
      </div>
      <div className='px-3'>
        <div className='p-2 card-slide'>
          <div className='d-flex justify-content-center p-2'>
            <img src='/assert/Homeimg/boy.png' style={{width: "80px"}} className='img-fluid' alt="Person" />
          </div>
          <div className='text-center'>
            <h3 style={{fontSize: "1.2rem", fontWeight: "600"}}>David R</h3>
            <p className='pt-2'>"WishIton takes the stress out of remembering birthdays. I can focus on celebrating rather than worrying about missing important dates."</p>
          </div>
        </div>
      </div>
    </Carousel>
            </div>

          </div>
        </Container>
      </section>
    </>
  )
}

export default Cards
