import React from 'react'
import "./About.css";
import Container from 'react-bootstrap/Container';
import "../../src/Privacy/Privacy.css"

const About = () => {
  return (
    <>
      <section style={{paddingTop:"6rem"}}>
        <Container>
        <div className='row pt-5 pb-5' >
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <h2 className='privacy-H'>About Wishiton</h2>
                    <p className='privacy-choose'>Wishiton is basically a pure entertainment and social networking platform for all kind of users.Getting all the event dates and store and schedule the notification of the date are purely handled by wishiton itself.We didn't share any kind of personal datas.Here are the few points that how wishiton collect and use your personal information data.</p>
                    <h2 className='info'>Google OAuth2 Homepage:</h2>
                    <h3 className='info-Per'>1. Retriving user data:</h3>
                        <p className='li-Priv'>You have to sign in with google to see more stuff. Anyone can sign in. If you sign in, you'll get access to some content that is just hidden from robots. If we recognize you, we'll grant you access to more protected content.When you sign in, google will share your name, email and profile picture and we also collect your calendar events for the purpose of importing the birthday events from your calendar with us. We won't share this data with others.</p>
                    <h3 className='info-Per'>2. What will our application do with user data:</h3>    
                         <p className='li-Priv'>The only user data received is: name, email and profile picture. These will be saved and re-displayed to the user.</p>   
                         <p className='li-Priv'>The Collected sensitive scopes user data values are handled securely that everyone can view the data only and wishiton didn't allow to overwrite those datas.(eg., if any user have a birthday event in current month means by clicking on import birthday from calendar we're just getting your calendar events and from that events we're filtered only birthday events and store it in our application and schedule one reminder) so the personal information datas are not shared to anyone and it's too secure .This how wishiton handles your data</p>
                    <h3 className='info-Per'>3. How does our application enhance user functionality:</h3>
                        <p className='li-Priv'>Our application retrives calendar events and created as a remainder and if any user wants to delete the stored data means they can easily delete and they can easily signout.So only enhancement of functionality is to access and view the user calendar events to create a reminder.</p>
                    <h2 className='info'>Google OAuth2 Limited Use Disclosure:</h2>
                    <h3 className='info-Per'  style={{paddingLeft:"2rem"}}>To Provide Services:</h3>
                        <p className='' style={{paddingLeft:"3rem"}}>Wishiton application doesn't request any restricted scopes, but if it did, the  <a href='https://wishiton.com/' target='_blank'>wishiton.com</a> App's use of information received from Google APIs will adhere to the <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                      <h2 className='info'>Handling Google User Data:</h2>
                    <h3 className='info-Per'> 1. Sensitive Scopes Information:</h3>
                        <li className='li-Priv'>Wishiton access your google calendar events provided by google OAuth Api's which will help wishiton to save your birthday events and you get reminder.</li>
                        <li className='li-Priv'>We assure that the retrived google user data are secured as per the terms and privacy policy condition of <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' style={{display:"contents"}} target='_blank'>Limited Use Requirements</a> and it's must comply with the <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank' style={{display:"contents"}}> Google API Services User Data Policy.</a></li>
                        <li className='li-Priv'>Wishiton assures that the share google user data is not shared anywhere and we didn't store that data also in our DB.</li>
                        <li className='li-Priv'>Wishiton doesn't do any perform like edit or delete the data information of the sensitive scopes.</li>
                    <div><h2 className='info'>Contact Us:</h2><p style={{paddingLeft:"3rem"}}>If you have any questions or concerns about our Privacy Policy, please contact us at <a href='mailto:noreply@wishiton.com' target='_blank'>noreply@wishiton.com</a>.
                        By using the Wishiton application, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p></div>
                </div>
            </div>
        </Container>
      </section>
    </>
  )
}

export default About
