import React from 'react'
import Header from '../Header/Header'
import LoginPage from '../Login/LoginPage'
import Footer from '../Footer/Footer'

const Login = () => {
    return (
        <>
            <Header/>
            <LoginPage/>
            <Footer/>
        </>
    )
}

export default Login
