import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../MainFiles/Home'
import PrivacyM from '../MainFiles/PrivacyM'
import Vendor from '../MainFiles/Vendor';
import Franchise from '../MainFiles/Franchise';
import Event from '../MainFiles/Event';
import Features from '../MainFiles/Features';
import Aboutus from '../MainFiles/Aboutus';
import Login from '../MainFiles/Login';
const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/privacy-policy' element={<PrivacyM/>}></Route>
          <Route path='/become-a-vendor' element={<Vendor/>}></Route>
          <Route path='/franchise' element={<Franchise/>}></Route>
          <Route path='/event-plan' element={<Event/>}></Route>
          <Route path='/features' element={<Features/>}></Route>
          <Route path='/about-us' element={<Aboutus/>}></Route>
          <Route path='/login' element={<Login/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Routing
