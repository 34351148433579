import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import "./Home.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaBell } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { FaCakeCandles } from "react-icons/fa6";



const Explore = () => {

 

      useEffect(() => {
        AOS.init();
    
        const handleScroll = () => {
          AOS.refresh();
          const elements = document.querySelectorAll('[data-aos]');
          
          elements.forEach(element => {
            const top = element.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (top < windowHeight - 100) {
              element.classList.add('aos-animate'); 
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
      <section style={{backgroundColor:"#BA4F98",paddingTop:"2rem"}}>
        <div style={{backgroundColor:"#78c7ce",borderTopLeftRadius:"250px",borderTopRightRadius:"250px"}}>
            <Container>
                <div className='row pt-5 pb-5'>
                    <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                        <h2 className='explore-H' data-aos="fade-right">Explore Features</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center p-3'>
                        <div className='text-center'>
                            <img src='/assert/Homeimg/img1.png' className='img-fluid' data-aos="flip-left"  data-aos-duration="3000" style={{width:"60%"}}/>
                            <h6 className='rem-H' data-aos="fade-up" data-aos-duration="2000">Reminders <FaBell className='bell'/></h6>
                            <p className='rem-P' data-aos="fade-up" data-aos-duration="2000">Reminders make sure that you never miss a celebration. Plus, they're completely customizable you can control everything down to the day and time.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center p-3'>
                        <div className='text-center'>
                            <img src='/assert/Homeimg/img2.png' className='img-fluid' data-aos="flip-left"  data-aos-duration="3000" style={{width:"60%"}}/>
                            <h6 className='rem-H' data-aos="fade-up" data-aos-duration="2000">Personal Gifts <FaGift className='gift'/></h6>
                            <p className='rem-P' data-aos="fade-up" data-aos-duration="2000">Send Personalized gift or choose from our suggestions and make the perfect birthday present to your friends & family.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center p-3'>
                        <div className='text-center'>
                            <img src='/assert/Homeimg/img3.png' className='img-fluid' data-aos="flip-left"  data-aos-duration="3000" style={{width:"60%"}}/>
                            <h6 className='rem-H' data-aos="fade-up" data-aos-duration="2000">Birthday Wishes <FaCakeCandles className='cake'/></h6>
                            <p className='rem-P' data-aos="fade-up" data-aos-duration="2000">Choose from dozens of greeting cards and phrases to celebrate the birthdays of your loved once.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
      </section>
    </>
  )
}

export default Explore
