import React from 'react'
import { Carousel } from 'react-bootstrap'
import "./Home.css"
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';


const Banner = () => {
  return (
    <>
        <Helmet>
            <title>WishItOn: Best Birthday Messaging App</title>
            <meta name="description" content="Don’t miss any event; send auto wishes on WhatsApp and other Messaging platforms for free. Download WishItOn today." />
        </Helmet>
        <Carousel style={{paddingTop:"9rem",background:"#BA4F98",height:"auto"}}>
            <Carousel.Item>
            <Container>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center p-2 mb-4'>
                        <img className='img-fluid' src='assert/Homeimg/Group 132.png' style={{width:"534px"}}/>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center p-2 mb-4'>
                        <div>
                            <div>
                                <img src='assert/Homeimg/birthday-name 1.png' className='img-fluid' style={{width:"268px"}}/>
                            </div>
                            <div>
                                <img className='img-fluid' src='assert/Homeimg/Reminder application.png' style={{width:"160px"}}/>
                            </div>
                            <div>
                                <img className='img-fluid' src='assert/Homeimg/uehduwh.png' style={{width:"136px",marginLeft:"1rem"}}/>
                            </div>
                            <div>
                                <p style={{color:"#fff",fontWeight:"400"}}  className='mb-5'>"Transform every moment into a celebration with 'Wish-It-On'  your go-to birthday and events reminder app. Never miss a special day again, as we make spreading joy and sending wishes effortlessly unforgettable!" </p>
                                <h5 style={{color:"#fff",fontWeight:"bold"}}>Download now on Ios & Android</h5>
                            </div>
                            <div>
                                <a href='https://play.google.com/store/apps/details?id=com.wish_it_on.yts' target='_blank'><img className='img-fluid' src='assert/Homeimg/android.png' style={{width:"125px",cursor:"pointer"}}/></a>
                                <a href='https://apps.apple.com/us/app/wish-it-on/id6473829634' target='_blank'><img className='img-fluid' src='assert/Homeimg/Ios-new.png' style={{width:"125px",marginLeft:"1rem",cursor:"pointer"}}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                </Container>
                {/* <img src='/assert/Homeimg/banner1.png' className='img-fluid'/> */}
            </Carousel.Item>
            <Carousel.Item>
            <Container>

            <div className='row'>
                <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center p-2 mb-4'>
                    <img className='img-fluid' src='assert/Homeimg/Group 133.png' style={{width:"534px"}}/>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center p-2 mb-4'>
                    <div>
                        <div className='mb-5'>
                            <img src='assert/Homeimg/Voice Assistant.png' className='img-fluid' style={{width:"448px"}}/>
                        </div>
                        <div>
                            <p style={{color:"#fff",fontWeight:"400"}}>"Wish it On introduces a voice assistant for seamless event planning. Just speak the details, and let the app effortlessly save and organize your special occasions."</p>
                            <h5 style={{color:"#fff",fontWeight:"bold"}}>Download now on Ios & Android</h5>
                        </div>
                        <div>
                            <a href='https://play.google.com/store/apps/details?id=com.wish_it_on.yts' target='_blank'><img className='img-fluid' src='assert/Homeimg/android.png' style={{width:"125px",cursor:"pointer"}}/></a>
                            <a href='https://apps.apple.com/us/app/wish-it-on/id6473829634' target='_blank'><img className='img-fluid' src='assert/Homeimg/Ios-new.png' style={{width:"125px",marginLeft:"1rem",cursor:"pointer"}}/></a>
                        </div>
                    </div>
                </div>
            </div>
            </Container>
                {/* <img src='/assert/Homeimg/banner2.png' className='img-fluid'/> */}
            </Carousel.Item>
            <Carousel.Item>
            <Container>

                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center  p-2 mb-4'>
                        <img className='img-fluid' src='assert/Homeimg/Group 134.png' style={{width:"534px"}}/>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center p-2 mb-4'>
                        <div>
                            <div className='mb-3'>
                                <img src='assert/Homeimg/Gift Shop.png' className='img-fluid' style={{width:"246px"}}/>
                            </div>
                            <div>
                                <p style={{color:"#fff",fontWeight:"400"}}>"Wish it On: A heartwarming app to effortlessly send gifts to loved ones on special occasions, with a unique twist! Now vendors can join too, showcasing and selling their products for those extra special moments."</p>
                                <h5 style={{color:"#fff",fontWeight:"bold"}}>Download now on Ios & Android</h5>
                            </div>
                            <div>
                                <a href='https://play.google.com/store/apps/details?id=com.wish_it_on.yts' target='_blank'><img className='img-fluid' src='assert/Homeimg/android.png' style={{width:"125px",cursor:"pointer"}}/></a>
                                <a href='https://apps.apple.com/us/app/wish-it-on/id6473829634' target='_blank'><img className='img-fluid' src='assert/Homeimg/Ios-new.png' style={{width:"125px",marginLeft:"1rem",cursor:"pointer"}}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                </Container>
                {/* <img src='/assert/Homeimg/banner3.png' className='img-fluid'/> */}
            </Carousel.Item>
           
        </Carousel>
    </>
  )
}

export default Banner
