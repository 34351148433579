import React, { useEffect } from 'react';
import VendorForm from './VendorForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './Routing/Routing';

function App() {

  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])
  
  return (
    <>
      <Routing/>
    </>
  );
}

export default App;
