import React from 'react'
import { Container } from 'react-bootstrap'
import { Input } from 'antd';
import { Button } from '@mui/material';


const LoginPage = () => {
    return (
        <>
        <section style={{backgroundColor:"#B94F98",paddingTop:"6rem"}}>
        <Container>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pt-5 pb-5'>
                    <div className='formVendor  d-flex justify-content-center align-items-center'>
                        <div className='pt-5 pb-5 px-2 py-1'>
                            <form>
                            <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-10'>
                                        <h2 style={{fontSize:"1.8rem",fontWeight:400,textAlign:"center"}}>LogIn</h2>
                                        <p className='text-center'>Get access to your Events , Reminders , Orders , Wishlist and Recommendations</p>
                                        <label className='pb-2 pt-3' style={{fontWeight:500,fontSize:"1rem"}}>EMAIL ADDRESS</label><br/>
                                        <Input placeholder="Your account email" className='p-2'/>
                                        <label className='pb-2 pt-3' style={{fontWeight:500,fontSize:"1rem"}}>PASSWORD</label><br/>
                                        <Input.Password placeholder="Enter your secure password" className='p-2' />
                                        <div className='pt-4 text-center'>
                                            <Button variant='contained' style={{backgroundColor:"#B94F98",width:"100px"}}>Login</Button>
                                        </div> 
                                        </div>                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Container>
      </section>  
        </>
    )
}

export default LoginPage
