import React from 'react'
import Header from '../Header/Header'
import Franchiseform from '../FranchiseForm/Franchiseform'
import Footer from '../Footer/Footer'
const Franchise = () => {
  return (
    <>
      <Header/>
      <Franchiseform/>
      <Footer/>
    </>
  )
}

export default Franchise
