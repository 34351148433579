import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import About from '../About/About'

const Aboutus = () => {
  return (
    <>
      <Header/>
      <About/>
      <Footer/>
    </>
  )
}

export default Aboutus
