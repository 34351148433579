import React from 'react'
import Container from 'react-bootstrap/Container';
import "./Footer.css"
import { Link, useNavigate } from 'react-router-dom';
import { BsPencilSquare } from "react-icons/bs";


const Footer = () => {
  const navigate=useNavigate()
  const navHome=()=>{
    navigate("/")
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  const navPrivacy=()=>{
    navigate("/privacy-policy")
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const navFeature=()=>{
    navigate("/features")
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  return (
    <>
      <section style={{backgroundColor:"#FFFFFF"}}>
        <Container>
          <div className='row pt-3'>
            <div className='col-lg-3 col-md-4 col-sm-12'>
              <div>
              <img src='assert/Footerimg/WishitOn Logo-02.png' className='img-fluid pb-4 f-logo' onClick={navHome}/>
              <p className='F-para'>Your personal event companion, ensuring you never miss a birthday or special occasion. Seamlessly organize and receive reminders for all your cherished events in one user-friendly mobile application.</p>
              <p className='foloowuson'>Follow Us On :</p>
                    <div>
                        <a href='https://www.facebook.com/profile.php?id=61553059492370' target='_blank'><img src='/assert/Footerimg/facebook.png' className='img-fluid' style={{width:"30px",height:"30px",margin:"10px"}}/></a>
                        <a href='https://www.instagram.com/wishiton123?igsh=eXhicXpuNDlkemRu' target='_blank'><img src='/assert/Footerimg/instagram.png' className='img-fluid' style={{width:"30px",height:"30px",margin:"10px"}}/></a>
                        <a href='https://www.snapchat.com/add/wishiton?share_id=8CJzfoO0Lz8&locale=en-GB' target='_blank'><img src='/assert/Footerimg/snapchat.png' className='img-fluid' style={{width:"30px",height:"30px",margin:"10px"}}/></a>
                    </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-12 lg-center'>
              <div className='app-feature mt-4'>
                <h3>App Features</h3>
                <ul className='px-0'>
                  <li><a href='#remainder' onClick={navFeature}>Birthday Reminder</a></li>
                  <li><a href='#notification' onClick={navFeature}>Birthday Notification</a></li>
                  <li><a href='#voice-assistant' onClick={navFeature}>Meet "wish" Voice Assistant</a></li>
                  <li><a href='#birthday-wishes' onClick={navFeature}>Send Birthday Wishes</a></li>
                  <li><a href='#bulk-wishes' onClick={navFeature}>Send Bulk Wishes</a></li>
                  <li><a href='#gift-shop' onClick={navFeature}>Gift Shop</a></li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-12 lg-center'>
              <div className='app-feature mt-4'>
              <h3>Business With Us</h3>
                <ul className='px-0'>
                  <li><Link to="/become-a-vendor">Become a Vendor</Link></li>
                  <li><Link to="/franchise">Become a Franchise</Link></li>
                  <li><Link to="/event-plan">Plan an Event</Link></li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-12 lg-center'>
              <div className='app-feature mt-4'>
                <h3>Information</h3>
                <ul className='px-0'>
                  <li><Link to="/about-us">About Us </Link></li>
                  <li><Link to="/privacy-policy">Privacy Policies</Link></li>
                </ul>
              </div>
            </div>
          </div>

            <div className='row pb-5'>
                <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                    <h6 className='D-PARA'>Download Wishiton Now</h6>
                    <h6 className='D-PARA'>Celebrate Moments, Never Miss Again</h6>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='col-lg-2 col-md-2 p-2'><a href='https://play.google.com/store/apps/details?id=com.wish_it_on.yts' target='_blank'><img src='/assert/Footerimg/playstore.png' className='img-fluid' style={{cursor:"pointer"}}/></a></div>
                        <div className='col-lg-2 col-md-2 p-2'><a href='https://apps.apple.com/us/app/wish-it-on/id6473829634' target='_blank'><img src='/assert/Footerimg/appstore.png' className='img-fluid' style={{cursor:"pointer"}}/></a></div>
                    </div>
                </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 copy'>
                <p>© Copyright <strong>Wishiton</strong>. All Rights Reserved</p>
                <p style={{textDecoration:"underline",color:"#BA4F98",cursor:"pointer"}} onClick={navPrivacy}><a><BsPencilSquare style={{paddingRight:"0.15rem"}}/>Privacy policy</a></p>
              </div>
            </div>
        </Container>
        <div className='pink-border'></div>
        <div className='green-border'></div>
      </section>
    </>
  )
}

export default Footer
