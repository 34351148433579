import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import "./VendorForm.css"
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Checkbox } from '@mui/material';
import { log } from 'console';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';

const animatedComponents = makeAnimated();

const VendorForm = () => {
    const dropdownValue=[{
        value: 'coke', label: 'Coke'
    },{
        value: 'chocolate', label: 'Chocolate'
    },{
        value: 'Flowers', label: 'Flowers'
    }]

    //useStates...
    const [vendorsubmit,isVendorSubmit]=useState(false)
    const [show, setShow] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [vendorData,setVendorData]=useState({shopName:"",phoneNumber:"",email:"",contactPersonName:"",shopAddress:"",city:"",pinCode:"",product:[],isGSTRegistered:"",productDescription:""})
   

    const handleClose = () => setShow(false);
    //Checkbox function
    const handleCheckboxChange = (checkboxNumber:any) => {
        if (checkboxNumber === 1) {
            setVendorData({...vendorData,isGSTRegistered:"Yes"})
            setIsChecked1(true);
            setIsChecked2(false);
        } else if (checkboxNumber === 2) {
            setVendorData({...vendorData,isGSTRegistered:"No"})
            setIsChecked1(false);
            setIsChecked2(true);
        }
    };

    //DropDown Function
    const handleSelect=(selectedOptions:any)=>{
        const selectedValues = selectedOptions.map((option: { value: any; }) => option.value);
        setVendorData({...vendorData,product:selectedValues})  
    }

    //Submit Function
    const handlevendorSubmit=async(e:any)=>{
        e.preventDefault()
        isVendorSubmit(true)
        await axios.post("https://wishiton-registration-api.onrender.com/VendorRequest",{shopName:vendorData.shopName,phoneNumber:vendorData.phoneNumber,email:vendorData.email,contactPerson:vendorData.contactPersonName,shopAddress:vendorData.shopAddress,city:vendorData.city,pinCode:vendorData.pinCode,Product:vendorData.product,Gst:vendorData.isGSTRegistered,Description:vendorData.productDescription})
        .then((success)=>{
            setShow(true)
            isVendorSubmit(false)
            setVendorData({shopName:"",phoneNumber:"",email:"",contactPersonName:"",shopAddress:"",city:"",pinCode:"",product:[],isGSTRegistered:"",productDescription:""})
            setIsChecked1(false);
            setIsChecked2(false);
        })
        .catch((error)=>{
          console.log(error);
        })
    }
  return (
    <>
      <section style={{backgroundColor:"#B94F98",paddingTop:"6rem"}}>
        <Container>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pt-5 pb-5'>
                    <div className='formVendor  d-flex justify-content-center align-items-center'>
                        <div className='pt-5 pb-5 px-2 py-1'>
                            <form onSubmit={(e)=>handlevendorSubmit(e)}>
                                <h2 className='vendor-formH'>Vendor Registration Application Form</h2>
                                <p className='vendor-formP pb-4'>Fill all the questions in the form to become a vendor on Wish-It-On</p>
                                <label className='d-block vendor-label pb-1'>Shop Name<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your shop name' type='text' className='vendor-input p-2 px-3' required value={vendorData.shopName} onChange={(e)=>setVendorData({...vendorData,shopName:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Phone Number<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your phone number' type='text' className='vendor-input p-2 px-3' required value={vendorData.phoneNumber} onChange={(e)=>setVendorData({...vendorData,phoneNumber:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Email<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your email' type='email' className='vendor-input p-2 px-3' required value={vendorData.email} onChange={(e)=>setVendorData({...vendorData,email:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Contact person name</label>
                                <input placeholder='Enter your name' type='text' className='vendor-input p-2 px-3' value={vendorData.contactPersonName} onChange={(e)=>setVendorData({...vendorData,contactPersonName:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Shop address</label>
                                <input placeholder='Enter your Shop address' type='text' className='vendor-input p-2 px-3' value={vendorData.shopAddress} onChange={(e)=>setVendorData({...vendorData,shopAddress:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>City<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your city' type='text' className='vendor-input p-2 px-3' value={vendorData.city}  required onChange={(e)=>setVendorData({...vendorData,city:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Pin code<span style={{color:"red"}}>*</span></label>
                                <input placeholder='Enter your pin code' type='text' className='vendor-input p-2 px-3' value={vendorData.pinCode} required onChange={(e)=>setVendorData({...vendorData,pinCode:e.target.value})}/>
                                <label className='d-block vendor-label pb-1 pt-3'>Product</label>
                                <Select
                                    components={animatedComponents}
                                    isMulti
                                    options={dropdownValue}
                                    placeholder="Select your product"
                                    closeMenuOnSelect={false}
                                    onChange={handleSelect}
                                />
                                <label className='d-block vendor-label pb-1 pt-3 '>Have you GST registered<span style={{color:"red"}}>*</span></label>
                                <div className='d-flex'>
                                    <div className='d-flex'><Checkbox  onChange={() => handleCheckboxChange(1)} className='p-2' checked={isChecked1}/><span className='checkbox-Vendor d-flex justify-content-center align-items-center'>Yes</span></div>
                                    <div className='d-flex px-5'><Checkbox  onChange={() => handleCheckboxChange(2)} className='p-2' checked={isChecked2}/><span className='checkbox-Vendor d-flex justify-content-center align-items-center'>No</span></div>
                                </div>
                                <label className='d-block vendor-label pb-1 pt-3'>Description about your shop and product</label>
                                <textarea className='vendor-input p-2 px-3' rows={5} required value={vendorData.productDescription} onChange={(e)=>setVendorData({...vendorData,productDescription:e.target.value})}/>
                                <div className='text-center pt-5'>
                                    {vendorsubmit?<button className='vendor-btn'><Bars
                                                height="35"
                                                width="80"
                                                color="#FFFFFF"
                                                ariaLabel="bars-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                             /></button>: <button className='vendor-btn' type='submit'>Submit</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Thanks for showing interest !</Modal.Title>
            </Modal.Header>
            <Modal.Body>We will respond you soon...</Modal.Body>
          </Modal>

        </Container>
      </section>
    </>
  )
}

export default VendorForm


