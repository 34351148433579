import React from 'react'
import Header from '../Header/Header'
import VendorForm from '../VendorForm'
import Footer from '../Footer/Footer'

const Vendor = () => {
  return (
    <>
      <Header/>
      <VendorForm/>
      <Footer/>
    </>
  )
}

export default Vendor
